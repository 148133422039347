import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

// Change the config to fix the flicker
config.mutateApproach = 'sync'

import {
    faCog,
    faPlus,
    faEdit,
    faArrowAltLeft,
    faCoins,
    faCalendarAlt,
    faBars,
    faUserCog,
    faUserPlus,
    faEnvelopeOpenText,
    faSignInAlt,
    faSignOutAlt,
    faCircle,
    faPlusCircle,
    faHome,
    faRedoAlt,
    faEnvelope,
    faSave,
    faTrashAlt
} from "@fortawesome/pro-light-svg-icons"

library.add(
    faCog,
    faPlus,
    faEdit,
    faArrowAltLeft,
    faCoins,
    faCalendarAlt,
    faBars,
    faUserCog,
    faUserPlus,
    faEnvelopeOpenText,
    faSignInAlt,
    faSignOutAlt,
    faCircle,
    faPlusCircle,
    faHome,
    faRedoAlt,
    faEnvelope,
    faSave,
    faTrashAlt
);

dom.watch();
